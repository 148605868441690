import { API } from "../../../constans/Url"
import { apiClient } from "../../../helper"

const get_StockOpnameList = (params, callbackSuccess, callbackError) => {
  apiClient(true).get(API.BARANG.STOCK_OPNAME.LIST, params).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const post_StockOpnameSave = (params, callbackSuccess, callbackError) => {
  apiClient(true).post(API.BARANG.STOCK_OPNAME.SAVE, params).then(res => {
    if (res.status === 201 || res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const get_StockOpnameDetail = (kode, callbackSuccess, callbackError) => {
  apiClient(true).get(API.BARANG.STOCK_OPNAME.GET, {kode: kode}).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const get_StockOpnamePraSave = (params, callbackSuccess, callbackError) => {
  apiClient(true).get(API.BARANG.STOCK_OPNAME.PRA_SAVE, params).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const post_StockOpnameSaveHasil = (params, callbackSuccess, callbackError) => {
  apiClient(true).post(API.BARANG.STOCK_OPNAME.SAVE_HASIL, params).then(res => {
    if (res.status === 201) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}


export {
  get_StockOpnameList,
  post_StockOpnameSave,
  get_StockOpnameDetail,
  get_StockOpnamePraSave,
  post_StockOpnameSaveHasil
}